.buttons-group {
  display: flex;
  justify-content: space-between;
}
.copy {
  color: var(--unnamed-color-2692ff);
  text-align: left;
  font: normal normal medium 16px/20px Inter;
  letter-spacing: 0px;
  color: #2692ff;
  opacity: 1;
}
.open {
  color: var(--unnamed-color-2692ff);
  text-align: left;
  font: normal normal medium 16px/20px Inter;
  letter-spacing: 0px;
  color: #2692ff;
  opacity: 1;
}
.contribute {
  color: var(--unnamed-color-ff681c);
  text-align: left;
  font: normal normal medium 16px/20px Inter;
  letter-spacing: 0px;
  color: #ff681c;
  opacity: 1;
}
.rfq-header {
  text-align: left;
  font: normal normal normal 32px/39px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.new-rfq {
  text-align: left;
  letter-spacing: 0px;
  color: #f2f2f2;
  opacity: 1;
}
.new-rfq :hover {
  background-color: yellow !important;
}
/* .rfqContainer{
  width : 100%
} */
.rfqContainer input.MuiInputBase-input.MuiOutlinedInput-input {
  color: var(--unnamed-color-686b76);
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #686b76;
  opacity: 1;
}
.rfqContainer input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent url("../../../../assets/calendar.svg") 0% 0% no-repeat
    padding-box;
}
.rfqContainer
  input.MuiInputBase-input.MuiInputBase-inputAdornedEnd.css-yz9k0d-MuiInputBase-input {
  margin-left: 10px;
}
.rfqContainer input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input {
  color: #686b76;
  margin-left: 10px;
}
a {
  text-decoration: none;
}


input[type="date"]::-webkit-calendar-picker-indicator {
  margin-right : 10px;
  margin-bottom : 5px
}