
.selectAutoComplete .MuiAutocomplete-inputRoot {
  padding: 0 !important;
  height: 32px !important;
  width: 220px !important;
}
.clients-dialog .MuiAutocomplete-inputRoot {
  padding: 0 !important;
  height: 32px !important;
  width: 210px !important;
}
.selectAutoComplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
  padding: 0px 8px !important;

}