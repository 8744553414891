.ag-body-vertical-scroll-viewport {
  overflow-y: hidden;
}
.ag-body-horizontal-scroll-viewport {
  overflow-x: hidden;
}

.custom {
  --ag-row-border-style: none;
}

.flicker2 {
  text-decoration: blink;
  animation-name: flicker2;
  animation-duration: 0.6s;
  animation-iteration-count: 20;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: #686b76; /* Add this line */
}
@keyframes flicker2 {
  0% {
    border-color: #222222;
  }
  50% {
    border-color: #2f5764;
  }
  100% {
    border-color: #76a5b4;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.ag-grid-custom {
  --ag-border-radius: 8px;
}
