@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');


#root {
    /* min-height: 80vh; */
    width: 100vw;
}

html {
    font-size : 70%
}



html,body {
    background: #171717 0% 0% no-repeat padding-box;
    margin: 0;
    height: fit-content;
    padding: 0;
    /* zoom:100%; */
    /* overflow-y:hidden;
    height: 100vh;
    width: 100vw;  */
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}