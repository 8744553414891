.time-picker-default {
  width: 210px;
  height: 33px;
  background-color: #2d2d2d;
  border-radius: 4px;
  color: #868da2;
  border: 1px solid #868da2;
  padding-left: 10px;
  margin: 0;
  /* margin-bottom: 15px; */
  /* margin-top: 3px; */
  /* height: 30px; */
}

.time-picker-default ~ .time-picker-label {
  border: 1px solid #2692ff;
}
.time-picker-default:focus-visible {
  outline-color: #2692ff;
  outline-style: double;
  outline-width: 1px;
}
.time-picker-default:focus {
  border: 1px solid #2692ff;
  outline-color: #2692ff;
  outline-style: double;
  outline-width: 1px;
}
.time-picker {
  width: 210px;
  height: 32px;
  background-color: #2d2d2d;
  border-radius: 4px;
  color: white;
  border: 1px solid #868da2;
  padding-left: 10px;
  margin: 0;
  height: 31px;
}
.time-picker:hover {
  border: 1px solid #2692ff;
}
.time-picker:focus-visible {
  outline-color: #2692ff;
  outline-style: double;
  outline-width: 1px;
}

.time-picker:focus {
  border: 1px solid #2692ff;
  outline-color: #2692ff;
  outline-style: double;
  outline-width: 1px;
}
.time-picker-label {
  width: 40px;
  color: #868da2;
  background-color: #2d2d2d;
  position: absolute;
  right: 170px;
  bottom: 30px;
  font-size: 0.7rem;
  text-align: center;
}
.time-picker-label:hover {
  color: #2692ff;
}
.time-wrapper {
  position: absolute;
  right: 84px;
  bottom: 260px;
  width: 221px;
  height: 35px;
}

.time-wrapper:hover :nth-child(1) {
  color: #2692ff;
}
.time-wrapper:hover :nth-child(2) {
  border: 1px solid #2692ff;
}

.time-picker-label:hover + .time-picker {
  border: 1px solid #2692ff;
}

label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


input {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 95%;
}


.time-picker::-webkit-calendar-picker-indicator {
  filter: invert(57%) sepia(75%) saturate(4277%) hue-rotate(191deg)
    brightness(100%) contrast(102%);
}
.time-picker-default::-webkit-calendar-picker-indicator {
  filter: invert(57%) sepia(75%) saturate(4277%) hue-rotate(191deg)
    brightness(100%) contrast(102%);
}
