.slide-flicker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3a3c3f;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both, flicker 0.6s infinite alternate;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes flicker {
  0% {
    background-color: #222222;
  }
  50% {
    background-color: #24414b;
  }
  100% {
    background-color: #2f5764;
  }
}