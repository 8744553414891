#underline-table {
  min-width: 950px;
  /* border-collapse: initial !important;
  border-spacing: initial !important; */
  /* text-indent: 0 !important; */
  border-collapse: separate !important;
  table-layout: auto;
}
/* @media (max-width:1205px) {
  #underline-table {
    display: none;
  }
} */
.tie-input::-webkit-outer-spin-button,
.tie-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flicker {
  text-decoration: blink;
  animation-name: blinkMe;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinkMe {
  0% {
    background-color: #222222;
  }
  50% {
    background: #24414b;
  }
  100% {
    background: #2f5764;
  }
}

.grid-transition {
  transition: all 0.3s ease-out;
  transform: scaleX(1);
}
.grid-transition.hidden {
  width: 10px;
  transform: scaleX(0);
}

.trash-icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #3a3c3f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-in-top {
  -webkit-animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.ag-grid-custom ::-webkit-scrollbar {
  height: 12px !important;
}
.ag-grid-custom ::-webkit-scrollbar-track {
  height: 12px !important;
}
.ag-grid-custom ::-webkit-scrollbar-thumb {
  height: 12px !important;
}

/* Handle */
/* ,
  ::-webkit-scrollbar-thumb {
    background-color: #3a3c3f;
    border-radius: 16px;
    border: 4px solid #222222;
  } */
/* } */

/* ::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-track {
  background: #222222;
  border-radius: 8px;
  width: 12px;
} */
