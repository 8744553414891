.jTable .jexcel_container {
  display: flex;
  justify-content: "start";
  margin-top: 20px;
}
.table.jexcel.jexcel_overflow {
  width: 0% !important;
}
.jexcel > thead > tr > td.selected {
  background-color: #171717 !important;
}
.jexcel > tbody > tr > td.userEditCell {
  border: 1px solid #ffffff !important;
}
textarea#jexcel_textarea {
  display: none;
}
.jexcel > thead > tr > td {
  font-size: 1.125rem !important;
  color: #ffffff !important;
}
table.jexcel colgroup col:first-child {
  width: 0 !important;
}
table.jexcel.jexcel_overflow {
  border: 1px solid var(--unnamed-color-686b76);
  border: 1px solid #686b76;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}
.jexcel_content {
  border: none !important;
  box-shadow: none !important;
}
table tr {
  background: #171717 0% 0% no-repeat padding-box;
}
.jexcel > thead > tr > td {
  border: 1px solid var(--unnamed-color-686b76);
  border: 1px solid #686b76;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  padding: 8px !important;
  font-size: 1.33rem;
  color: var(--unnamed-color-686b76);
  text-align: left;
  font: normal normal normal 1.1rem Inter;
  letter-spacing: 0px;
  color: #686b76;
  opacity: 1;
  background: #171717;
  line-height: 10px;
}
th {
  text-align: left;
}
/* tr:nth-child(even) {
  background: #2d2d2d;
  text-align: left;
  font: normal normal normal 1rem Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

tr:nth-child(odd) {
  background: #222222;
  text-align: left;
  font: normal normal normal 1rem  Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border-right: 1px solid transparent;
} */

.jexcel > tbody > tr {
  background: #3a3b3e !important;
  text-align: left;
  font: normal normal normal 1rem Inter;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  max-width: 175px !important;
}

.jexcel > tbody > tr > td {
  border-top: 0 !important;
  border-left: 0 !important;
  opacity: 1;
  padding: 6px;
  border: 1px solid var(--unnamed-color-686b76);
  border: 1px solid #686b76;
  opacity: 1;
  white-space: nowrap;
  text-align: left !important;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 1.05rem;
}
.jexcel > tbody > tr > td:last-child {
  color: var(--unnamed-color-989ea8);
  text-align: left;
  font: normal normal normal 1rem Inter;
  letter-spacing: 0px;
  opacity: 1;
}
.jexcel > tbody > tr > td.readonly {
  color: #ffffff;
}
.jexcel_row td {
  background: #a52a2a;
}

.jTable input {
  color: white !important;
  font-size: 1rem;
}
.jexcel > tbody > tr > td.arrow-icon {
  background: url("../../../../assets/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 4% bottom 50%;
}
.jexcel > tbody > tr > td.toggle-icon {
  background: url("../../../../assets/arrows.svg");
  background-repeat: no-repeat;
  background-position: right 4% bottom 50%;
}
.readonly {
  /* pointer-events: none; */
}
.jTable input[type="text"] {
  background: #2d2d2d !important;
}
/* .readonly{
font-size: 14px;
padding-top: none !important;

} */
