.MuiPickersDatePickerRoot-toolbar {
  background-color: #2692ff !important;
}
.MuiPickersDay-daySelected {
  background-color: #2692ff !important;
}

.MuiPickersToolbarText-toolbarBtnSelected {
  margin-left: 10px !important;
}
.MuiPickersToolbarText-toolbarTxt {
  margin-left: 10px !important;
}
.MuiPickersDay-current {
  color: #b9b9b9 !important;
}
