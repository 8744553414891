.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1 1 0px;
  width: 100%;

  --ag-value-change-delta-down-color: rgb(0, 200, 160);
  --ag-value-change-delta-up-color: rgb(255, 0, 80);
}

.align-right {
  text-align: right;
}

.select-strategy {
  background: none;
  background-color: none;

  border: none;
}
.select-strategy:focus option {
  background: none;
  background-color: #2b2b2b;
  color: white;
}

.select-strategy:focus {
  outline: none;
}

/* .ag-theme-balham-dark {
  --ag-header-height: 30px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: black;
  --ag-header-cell-hover-background-color: rgb(80, 40, 140);
  --ag-header-cell-moving-background-color: rgb(80, 40, 140);
} */
/* 
.ag-theme-balham-dark .ag-header-cell {
}
.ag-theme-balham-dark .ag-header {
}
.ag-theme-balham-dark .ag-header-group-cell {
} */

/* .ag-focus-managed {
  width: 150px;
} */

.my-header-class-xsmall {
  width: 50px !important;
}
.my-header-class-small {
  width: 70px !important;
}
.my-header-class-medium {
  width: 80px !important;
}
.ag-theme-balham-dark {
  
  /* --ag-list-item-height: 15px;
  --ag-font-size: 12px;
  --ag-grid-size: 5px;
  --ag-header-height: 5;
  --ag-cell-horizontal-padding: 5;
  --ag-widget-horizontal-spacing: 5; */

  /* --ag-widget-container-horizontal-padding: 0;
  --ag-widget-container-vertical-padding: 0;
  ---ag-widget-horizontal-spacing: 0;
  --ag-widget-vertical-spacing: 0;
  --ag-cell-horizontal-padding: 0;
  --ag-row-height: 0;
  --ag-list-item-height: 0;
  --ag-column-select-indent-size: 0;
  --ag-set-filter-indent-size: 0; */
}
