/* Scope styles to only affect grids with the custom-grid class */
.custom-grid.ag-theme-balham-dark .ag-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.custom-grid.ag-theme-balham-dark .ag-row-odd {
  background-color: #222222; /* Set your primary color */
}

.custom-grid.ag-theme-balham-dark .ag-row-even {
  background-color: #2d2d2d; /* Set your secondary color */
}

.custom-grid.ag-theme-balham-dark .ag-cell {
  border-right: 1px solid #3d3f46;
}

.custom-grid.ag-theme-balham-dark .ag-cell:last-child {
  border-right: none;
}
