/* Ensure the outer container allows overflow */
.e_report .ag-rich-select-list {
  overflow: visible;
}

/* Styling for .ag-rich-select-row */
.e_report .ag-rich-select-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

/* Hover effect to show full content */
.e_report .ag-rich-select-row:hover {
  overflow: visible;
  position: relative;
  z-index: 1000; /* High z-index to ensure it's on top */
  background-color: transparent; /* No background color as per your request */
  max-width: none;
}

/* Expanding the parent container on hover might help */
.e_report .ag-virtual-list-item:hover {
  overflow: visible;
  position: relative;
}

.e_report .ag-center-cols-viewport {
  height: calc(100% + 50px) !important; /* Adjust the height as needed */
}
