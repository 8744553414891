.fi-header {
  text-align: left;
  font: normal normal normal 32px/39px Inter;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
}
.MuiTableCell-root.MuiTableCell-head {
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid #686b76;
}
/* 
.MuiSvgIcon-root {
  color: #686b76;
} */

.newFiTrade {
  text-decoration: blink;
  animation-name: blinkMe;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinkMe {
  0% {
    background-color: #222222;
  }
  50% {
    background: #24414b;
  }
  100% {
    background: #2f5764;
  }
}
.updateFiTrade {
  text-decoration: blink;
  animation-name: blinkMe1;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinkMe1 {
  0% {
    background-color: #222222;
  }
  50% {
    background: #be8f02;
  }
  100% {
    background: #ffbf00;
  }
}

.select-date-format {
  background-color: #171717;
  color: white;
  border: none;
  width: 120px;
  margin-left: 5px;
  
}

.select-date-picker .MuiInputBase-input {
  position: absolute;
  left: 125px;
  padding: 0;
  width: 110px;
  height: 75px;
}

#sort-header:hover {
  color: white !important;
}
#sort-header:focus {
  color: white !important;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
  fill: red;
} */
