.newRates {
  text-decoration: blink;
  animation-name: blinkMe;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
@keyframes blinkMe {
  0% {
    background-color: #222222;
  }
  50% {
    background: #24414b;
  }
  100% {
    background: #2f5764;
  }
}

