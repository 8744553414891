$stroke-dasharray-loading: 40% 0% 40% 1000%;
$stroke-dasharray-error: 55% 48% 55% 1000%;

.loader {
  scale: 1;
  width: 45px;
  height: 45px;
  transform: rotateZ(0deg);

  &.loading {
    animation-name: rotate-loader;
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: normal;
  }
  .loader-{
    &circle {
      fill: #0275d8;
      transition: fill .3s ease;
    }
    &path {
      fill: transparent;
      stroke: white;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: $stroke-dasharray-loading;
      stroke-dashoffset: -176%;
    }
  }
  &.success, &.error {
    animation-name: none;
  }
  &.success {
    .loader-{
      &circle {
        fill: #5cb85c;
      }
      &path {
        animation-name: loader-success;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
  &.error {
    .loader-{
      &circle {
        fill: #c9302c;
      }
      &path {
        animation-name: loader-error;
        animation-duration: 0.6s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes rotate-loader {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg)
  }
}
@keyframes loader-success {
  0% {
    stroke-dashoffset: -176%
  }
  100% {
    stroke-dashoffset: -44%;
  }
}

@keyframes loader-error {
  0% {
    stroke-dasharray: $stroke-dasharray-loading;
    stroke-dashoffset: -176%;
  }
  35% {
    stroke-dasharray: $stroke-dasharray-loading;
  }
  100% {
    stroke-dasharray: $stroke-dasharray-error;
    stroke-dashoffset: -1;
  }
}

// Positions
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//////! SMALL LOADER
.loaderSmall {
  scale: 1;
  width: 25px;
  height: 25px;
  transform: rotateZ(0deg);

  &.loading {
    animation-name: rotate-loaderSmall;
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: normal;
  }
  .loaderSmall-{
    &circle {
      fill: #0275d8;
      transition: fill .3s ease;
    }
    &path {
      fill: transparent;
      stroke: white;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: $stroke-dasharray-loading;
      stroke-dashoffset: -176%;
    }
  }
  &.success, &.error {
    animation-name: none;
  }
  &.success {
    .loaderSmall-{
      &circle {
        fill: #5cb85c;
      }
      &path {
        animation-name: loaderSmall-success;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
  &.error {
    .loaderSmall-{
      &circle {
        fill: #c9302c;
      }
      &path {
        animation-name: loaderSmall-error;
        animation-duration: 0.6s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
}


@keyframes rotate-loaderSmall {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg)
  }
}
@keyframes loaderSmall-success {
  0% {
    stroke-dashoffset: -176%
  }
  100% {
    stroke-dashoffset: -44%;
  }
}

@keyframes loaderSmall-error {
  0% {
    stroke-dasharray: $stroke-dasharray-loading;
    stroke-dashoffset: -176%;
  }
  35% {
    stroke-dasharray: $stroke-dasharray-loading;
  }
  100% {
    stroke-dasharray: $stroke-dasharray-error;
    stroke-dashoffset: -1;
  }
}

// Positions
.loaderSmall-container {
  position: absolute;
  top: calc(100% - 3px);
  left: calc(100% - 122px);
  transform: translate(-50%, -50%);
}