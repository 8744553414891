.rfqTableContainer{
  width : 100%;
}
.css-i4bv87-MuiSvgIcon-root {
  /* color: #ff4e32 !important; */
  /* opacity: 1 !important; */
  /* height: 10px !important; */
  /* width: 10px !important; */
  
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  height: 48px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
  color: black;
}
.headerText {
  color: var(--unnamed-color-686b76);
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #686b76;
}
.rfq-descriprion {
  text-align: left;
  font: normal normal normal 18px/21px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.statusTextGreen {
  color: var(--unnamed-color-00d865);
  text-align: left;
  font: normal normal normal 18px/21px Inter;
  letter-spacing: 0px;
  color: #00d865;
}
.statusTextWhite {
  text-align: left;
  font: normal normal normal 18px/21px Inter;
  letter-spacing: 0px;
  color: #ff4e32;
  opacity: 1;
}
.basic-style {
  font: normal normal normal 18px/21px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.basic-style-plus {
  font: normal normal normal 18px/21px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.not-basic-style{
font-size: 0.08rem;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root:hover,
.MuiTableSortLabel-icon {
  color: #686b76 !important;
}
.tableRowHeader {
  background: #171717 0% 0% no-repeat padding-box !important;
}
.MuiTableCell-root.WithStyles\(ForwardRef\(TableCell\)\)-root-106.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  height: 54px !important;
}

.newRfq{
  height: 13px;
  width: 42px;
  border: 1px solid #539BB5;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: normal normal medium 12px/13px Inter;
  letter-spacing: 0px;
  color: #539BB5;
}
.wsNewRfk {
  height: 54px;
  text-decoration: blink;
	animation-name: blinkMe;
	animation-duration: 0.6s;
	animation-iteration-count:infinite;
	animation-timing-function:ease-in-out;
	animation-direction: alternate;
 }
 @keyframes blinkMe {
  0% {
   background-color: #222222; 
  }
  50% {
   background:#24414B;
  }
  100% {
    background:#24414B;
  }
}
