@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

.jTable-aggregator.jexcel_container {
  display: flex;
  justify-content: "start";
  margin-top: 20px;
}

.jexcel > thead > tr > td.selected {
  background-color: #171717 !important;
}

.jTable-aggregator tbody tr:nth-child(1) td{
  border-bottom:1px solid #FFC5CD !important;
}
.jTable-aggregator tbody tr:nth-child(16) td{
  border-bottom:1px solid #FFC5CD !important;
}

textarea#jexcel_textarea {
  display: none;
}
.jTable-aggregator .jexcel > thead > tr > td {
  font-size: 0.85rem !important;
  color: white !important;
}
table.jexcel colgroup col:first-child {
  width: 0 !important;
}
.jTable-aggregator .jexcel.jexcel_overflow {
  width: 100%;
  border: 1px solid var(--unnamed-color-686b76);
  border: 1px solid #686b76;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}
table tr {
  /* background: #171717 0% 0% no-repeat padding-box; */
}
.jTable-aggregator .jexcel > thead > tr > td {
  border: 1px solid var(--unnamed-color-686b76);
  border: 1px solid #686b76;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  padding: 10px !important;
  font-size: 16px;
  color: var(--unnamed-color-686b76);
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #686b76;
  opacity: 1;
  background: #171717;
}

.jTable-aggregator .jexcel > tbody > tr > td {
  border-top: 0 !important;
  border-left: 0 !important;
  /* padding-block : 10px 9px; */
  font-size: 1rem;
  border: 1px solid var(--unnamed-color-686b76);
  border: 1px solid #686b76;
  opacity: 1;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: center !important;
  /* padding-left: 10px; */
}
.jTable-aggregator .jexcel > tbody > tr > td:last-child {
  color: var(--unnamed-color-989ea8);
  /* font: normal normal normal 18px/21px Inter; */
  letter-spacing: 0px;
  opacity: 1;
}
.jexcel > tbody > tr > td.readonly {
  color: #ffffff;
}
.jexcel_row td {
  background: #a52a2a;
}
.jTable-aggregator input {
  /* font: normal normal normal 18px/21px Inter; */
  font-size: 1.083rem;
  color: white !important;

}

/* .rfqAggregator .jexcel > thead > tr > td:nth-child(2) {
  background: url("../../../assets/settings.svg");
  background-repeat: no-repeat;
  background-position: right 4% bottom 50%;
} */
/* .menu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  top: 285px !important;
  left: 300px !important;
} */
 .rfqAggregator tr:nth-child(even) {
  text-align: left;
  /* font: normal normal normal 13px Inter; */
 
  font-size: 1.083rem;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.rfqAggregator tr:nth-child(odd) {
  text-align: left;
  /* font: normal normal normal 13px Inter; */
  font-size: 1.083rem;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border-right: 1px solid transparent; 
 } 

.menu ul.MuiList-root.MuiMenu-list.MuiList-padding {
  background: #2d2d2d 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #000000c7;
  border-radius: 8px;
  opacity: 1;
  overflow: auto !important;
  max-height: 30vh !important;
  width: 10vw !important;
  height: 30vh !important;
}
ul.MuiList-root.MuiMenu-list.MuiList-padding::-webkit-scrollbar {
  width: 6px;
}
ul.MuiList-root.MuiMenu-list.MuiList-padding::-webkit-scrollbar-track {
  background: black;
}
ul.MuiList-root.MuiMenu-list.MuiList-padding::-webkit-scrollbar-thumb {
  background-color: #2d2d2d;
  border-radius: 20px; 
}
.menu input.MuiInputBase-input.MuiInputBase-inputAdornedEnd.css-yz9k0d-MuiInputBase-input {
  margin-top: 10px;
  border: 1px solid #686B76;
  border-radius: 4px;
  opacity: 1;
}
.jTable-aggregator td.readonly {
  text-align: center;
}