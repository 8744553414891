/* DO NOT DELETE!*/
/* sending Loader  */

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400&display=swap');
.fiTable tr {
  background-color: transparent;
  color: #000000;
}

.ogLogo {
  width: 100px;
  height: 150px;
  margin-left: 10px;
}
.makorLogo {
  width: 250px;
  height: 120px;
  margin-left: 10px;
}


.sendingAnim {
  font-size: 14px;
  /* padding: 0 30px 8px 0; */
  background: repeating-linear-gradient(90deg, currentColor 0 8%, #0000 0 10%)
    200% 100%/200% 2px no-repeat;
  animation: sendingAnim 2s steps(6) infinite;
}

.sendingAnimETG {
  margin-top: 4px;
  font-size: 1rem;
  padding: 0 5px 5px 0;
  background: repeating-linear-gradient(90deg, currentColor 0 8%, #0000 0 10%)
    200% 100%/200% 1px no-repeat;
  animation: sendingAnim 2s steps(6) infinite;
}

/* .launchingAnim {
  color: #ffffff ;
  text-align: "center";
  font-size: 1.125rem;
  padding: 0 5px 2px 0;
  background: repeating-linear-gradient(90deg, currentColor 0 8%, #0000 0 10%)
    200% 100%/200% 2px no-repeat;
  animation: sendingAnim 2s steps(6) infinite;
} */

@keyframes sendingAnim {
  to {
    background-position: 80% 100%;
  }
}

/* * FI EMAIL PREVIEW */
.fiEmailPreview {
  position: relative;
  max-height: fit-content;
  font: 14px Arial, sans-serif;
}

.traderComment {
  margin-right: 2;
  height: fit-content;
  position: relative;
  width: 770px;
  padding-bottom: 1px;
}

.traderComment pre{
  font-size: 14px;
  font: 14px Arial, sans-serif;
}

.makorFiLogo{
  margin-left: 0px;
}

/* * FX EMAIL PREVIEW */
.emailPreview {
  position: relative;
  max-height: 200px;
  font: 14px Arial, sans-serif;
}

.emailPreview pre{
  font-size: 14px;
  font: 14px Arial, sans-serif;
}

.compDetails {
  width: 85%;
}
.companyDetailsTd {
  border-collapse: collapse;
  background-color: #ffffff;
}

.companyDetailsImg {
  border-collapse: collapse;
  background-color: #ffffff;
  width: 200px;
}

.companyDetailsTd span {
  color: #000000;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  line-height: 10px;
  white-space: nowrap;
}

.emailPreview h4 {
  font-family: Arial, Helvetica, sans-serif;
}

.dataTable {
  width: 80%;
  border-collapse: collapse;
}

.dataTable tbody tr td {
  border-collapse: collapse;
  border: 1px solid #99ccff;
  font-size: 1.0625rem;
}

.dataTable td:not(.blueTd) {
  background-color: #ccffff;
  padding: 0px 30px;
  font-size: 1.125rem;
  color: #000000;
  text-align: center;
  /* font-weight: bold; */
}

.dataTable thead td {
  text-align: center;
  font-size: 1rem;
}
.legTable {
  width: 50%;
  border-collapse: collapse;
}

.legTable tbody tr td {
  border-collapse: collapse;
  border: 1px solid #99ccff;
  font-size: 1.0625rem;
}

.legTable td:not(.blueTd) td:not(.swapData){
  background-color: #ccffff;
  padding: 0px 30px;
  font-size: 1.125rem;
  color: #000000;
  text-align: center;
  /* font-weight: bold; */
}

.legTable thead td {
  text-align: center;
  font-size: 1rem;
}

.blueTd {
  background-color: #0066cc;
  padding-inline: 12px;
  color: #ffffff;
}

.swapData{
  background-color: #000000;
}

.headerTable {
  width: 80%;
  font-size: 1rem;
}

.headerTable .rightHederTd {
  text-align: right;
}

.headerTable td h4 {
  font-size: 1.416rem;
}

.headerTable td {
  background-color: #ffffff;
}

.desclimerTable td {
  background-color: #ffffff;
  color: #000000;
  font-size: 1.16rem;
}

#emailWrapper::-webkit-scrollbar {
  width: 16px;
}

/* background of the scrollbar */

#emailWrapper::-webkit-scrollbar-track {
  background: #fcfbf9;
  border: 1px solid #f3f2f0;
  width: 10px;
  border-radius: 9px;
}

/* Handle */

#emailWrapper::-webkit-scrollbar-thumb {
  background-color: #818b99;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

/* Handle on hover */

#emailWrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#emailWrapper::-webkit-scrollbar-button {
  display: none;
}

#commentBox::-webkit-scrollbar {
  width: 16px;
}

/* background of the scrollbar */

#commentBox::-webkit-scrollbar-track {
  background: #fcfbf9;
  border: 1px solid #f3f2f0;
  width: 10px;
  border-radius: 9px;
}

/* Handle */

#commentBox::-webkit-scrollbar-thumb {
  background-color: #818b99;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

/* Handle on hover */

#commentBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#commentBox::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar {
  width: 16px;
}

/* background of the scrollbar */

::-webkit-scrollbar-track {
  background: #222222;
  border-radius: 8px;
  width: 12px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #3a3c3f;
  border-radius: 16px;
  border: 4px solid #222222;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-button {
  display: none;
}

/* Track */

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: red;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent url("assets/calendar.svg") 0% 0% no-repeat padding-box;
}


body {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}


::-webkit-scrollbar-corner { background: transparent }
