.tool-bar-container{
    background: #3A3C3F 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 50px;
    flex-direction: column;
    height: 107vh;
    display: flex;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
}
.MuiToolbar-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.notification-badge {
    position: relative;
    top: -30px;
    left: 15px;
    width: 15px;
    height: 15px;
    background: #539BB5 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    font: normal normal normal 10px/16px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 8px;
}
.MuiToolbar-root.MuiToolbar-regular.tool-bar-container.MuiToolbar-gutters {
    min-width: 55px;
}
.hide-notifaction{
    display: none;
}
.login {
    margin-top: auto;
    margin-bottom: 50px;
}
.tool-bar-container ul.MuiList-root.MuiMenu-list.MuiList-padding {
    height: 37px;
    width: 90px;
    overflow: hidden;
    background-color:  #171717;
}
.profil{
    color: #000000;
}
.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 18px !important;
}
.MuiListItem-root{
    padding-top: 0px !important;
}
